<template>
  <vs-row vs-justify="center">
    <vs-col vs-type="flex" vs-justify="center" vs-align="center" vs-lg="8" vs-sm="12" vs-xs="12">
      <vs-card actionable style="margin-top: 3%; padding-bottom: 1%">
        <div>
          <h2 align="center" justify="center" style="margin: 2% 0 3% 0" class="title">
            CUSTOM DISPATCH REQUEST FORM
          </h2>
          <vs-row vs-justify="center">
            <vs-col vs-w="4">
              <div>
                <vs-input size="large" class="inputfeild" v-model="name" placeholder="Recepeint Name" />
                <vs-input size="large" class="inputfeild" v-model="address1" placeholder="Address Line 1" />
                <vs-input size="large" class="inputfeild" v-model="address2" placeholder="Address Line 2" />
                <vs-input size="large" class="inputfeild" v-model="city" placeholder="City" />
                <vs-input size="large" class="inputfeild" v-model="state" placeholder="State" />
                <vs-input size="large" class="inputfeild" v-model="pincode" placeholder="Pincode" />
                <vs-input size="large" class="inputfeild" v-model="mobile" placeholder="Mobile" />
              </div>
            </vs-col>
            <vs-col vs-w="4">
              <vs-select size="large" autocomplete class="inputfeild" v-model="book_name" placeholder="SKU">
                <vs-select-item :key="index" :value="item.name" :text="item.name" v-for="(item, index) in skudata" />
              </vs-select>

              <div v-for="(book, id) in search_result" :key="id">
                <vs-input size="large" class="vs-inputt" style="
                    margin-bottom: 4%;
                    width: 55%;
                    display: inline-block;
                    margin-right: 2%;
                  " disabled v-model="book.name" />
                <vs-input size="large" style="width: 18%; display: inline-block; margin-right: 2%"
                  v-model="book.quantity"></vs-input>
                <vs-button style="
                    display: inline-flex;
                    margin-right: 2%;
                    width: 9% !important;
                    height: 9% !important;
                    justify-content: center;
                    align: center;
                  " @click="deletebook(book.name)" v-model="book.name" icon="clear">
                </vs-button>
              </div>
              <vs-input size="large" class="inputfeild" v-model="invoice_number" placeholder="Invoice #" />
            </vs-col>
            <vs-col vs-w="4">
              <vs-input size="large" class="inputfeild" v-model="purpose" placeholder="Purpose" />
              <vs-select size="large" class="inputfeild" v-model="type" placeholder="Type">
                <vs-select-item value="additional_purchase" text="Additional Purchase" />
                <vs-select-item value="samples" text="Samples" />
                <vs-select-item value="replacement" text="Replacement" />
              </vs-select>
              <!-- <vs-input size="large" class="inputfeild" v-model="raised_by_user_id" placeholder="Raised by :"
                required /> -->
              <!-- <vs-select size="large" class="inputfeild" v-model="book_status"
                placeholder="Approve / Rejected / Pending / Fulfilled">
                <vs-select-item :key="index" :value="item.text" :text="item.text" v-for="(item, index) in options1" />
              </vs-select> -->
              <!-- <vs-input size="large" class="inputfeild" v-model="status_by" placeholder="Approved / rejected by" /> -->
            </vs-col>
          </vs-row>
          <div class="formbuttons">
            <vs-button class="formbtn1" type="submit" @click="submitdetails"><b>APPROVE</b></vs-button>
          </div>
        </div>
      </vs-card>
    </vs-col>
  </vs-row>
</template>

<script>
import axios from "axios";
import constants from "../../../constants.json";
export default {
  data() {
    return {
      name: "",
      address1: "",
      address2: "",
      city: "",
      state: "",
      mobile: null,
      email: "",
      pincode: null,
      book_name: "",
      quantity: null,
      invoice_number: null,
      raised_by_user_id: null,
      status_by: "",
      book_status: "",
      purpose: "",
      options1: [
        { text: "Approved", value: 1 },
        { text: "Rejected", value: 2 },
        { text: "Pending", value: 3 },
        { text: "Fulfilled", value: 4 },
      ],
      type: "",
      skudata: [],
      search_result: [],
      dispatch_request: [],
    };
  },
  mounted() {
    this.getCourierDetails();
  },
  watch: {
    book_name: function (value) {
      this.searchsubmit(value);
    },
  },
  computed: {
    filteredBooks() {
      return this.skudata.filter((p) => {
        return p.name.toLowerCase().indexOf(this.book_name.toLowerCase()) != -1;
      });
    },
  },
  methods: {
    getCourierDetails() {
      let url = `${constants.MILES_WAREHOUSE}sku`;
      axios
        .get(url, {
          headers: { Authorization: `Bearer ${localStorage.userAccessToken}` },
        })
        .then((response) => {
          console.log(response.data.data);
          this.skudata = response.data.data;
        });
    },
    autoCompleteFunc(value) {
      console.log("value", value);
    },
    deletebook(name) {
      for (let i = 0; i < this.search_result.length; i++) {
        if (this.search_result[i].name == name) {
          this.search_result.splice(i, 1);
          console.log(this.search_result[i]);
        }
      }
    },
    searchsubmit(bookname) {
      for (let i = 0; i < this.skudata.length; i++) {
        if (bookname.toLowerCase() == this.skudata[i].name.toLowerCase()) {
          if (
            !this.search_result.find((x) => x.name === this.skudata[i].name)
          ) {
            let sku_id = this.skudata[i].id;
            let name = this.skudata[i].name;
            let quantity = this.quantity;

            this.search_result.push({ sku_id, name, quantity });
            console.log(JSON.parse(JSON.stringify(this.search_result)));
          } else {
            alert("book has been entered already");
          }
        }
      }
    },
    submitdetails(e) {
      for (let i = 0; i < this.search_result.length; i++) {
        let sku_id = this.search_result[i].sku_id;
        let quantity = this.search_result[i].quantity;
        this.dispatch_request.push({ sku_id, quantity });
      }
      if (this.search_result.length != 0 && this.name && this.address1 && this.address2 && this.city && this.state && this.pincode && this.mobile && this.invoice_number && this.purpose && this.raised_by_user_id && this.book_status && this.status_by !== "") {
        e.preventDefault();
      }
      let dispatch_request = JSON.parse(JSON.stringify(this.dispatch_request))
      let params = {
        type: this.type, 
        purpose: this.purpose, 
        name: this.name, 
        address1: this.address1, 
        address2: this.address2, 
        city: this.city, 
        state: this.state, 
        pincode: this.pincode,
        mobile: this.mobile, 
        email: this.email, 
        invoice_number: this.invoice_number, 
        dispatch_request: dispatch_request
      }
      console.log(params);
      axios
        .post(`${constants.MILES_WAREHOUSE}dispatchRequest`, params, {
          headers: { Authorization: `Bearer ${localStorage.userAccessToken}` },
        })
        .then((response) => {
          console.log(response);
           if (response.data.status == "success") {
                        this.$vs.notify({
                            title: `Success`,
                            text: response.data.message,
                            color: "success",
                        });
                    }
        })
        .catch((error) => {
        this.$vs.notify({
                        title: `Error`,
                        text: error,
                        color: "danger",
                    });
        });
    },
  },
};
</script>

<style scoped>
.inputfeild {
  margin-bottom: 2%;
  width: 86%;
  height: 54px;
}

.formbtn1,
.formbtn2 {
  width: 121px;
  height: 36px;
}

.formbtn1 {
  background-color: rgb(114, 36, 187) !important;
  margin-right: 7%;
}

.formbtn2 {
  background-color: rgba(252, 38, 205, 0.842) !important;
  margin-right: 6%;
}

.formbuttons {
  display: flex;
  justify-content: flex-end;
}

.title {
  font-family: roboto;
  font-weight: bold;
  letter-spacing: 0.8px;
}
</style>